import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import GlobalStyle from './src/styles/global'
import './src/styles/normalize.css'
import theme from './src/styles/theme'
import 'prism-github/prism-github.css'

const RootContainer = styled.div`
  max-width: 1000px;
  width: 80%;
  margin: 0px auto;
  overflow: hidden;
`

export const wrapRootElement = ({ element }) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <span>
          <RootContainer>{element}</RootContainer>
        </span>
      </ThemeProvider>
    </React.Fragment>
  )
}
