import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nunito', Helvetica, Arial, Helvetica, sans-serif;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: 600;
  }

  code {
    overflow-x: scroll;
    border-radius: 10px;
  }
`

export default GlobalStyle
